body {
  font-family: 'Montserrat', sans-serif;
  background: black;
}
.logo-header {
  font-weight: 900;
  font-size: 7vw;
  text-align: center;
  color: black;
  padding: 40px 0;
}
.navbar-ania {
  background-color: transparent;
}
.navbar-center {
  justify-content: center;
  margin: 0 auto;
  text-transform: uppercase;
}
.navbar-ania-item {
  color: black;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}
.navbar-in {
  width: 150px;
  color: black;
  background-color: transparent;
}
.navbar-in:focus-within {
  width: 150px;
  color: black;
  background-color: transparent;
}
a.navbar-item:focus-within {
  background-color: transparent;
}
.navbar-ania-item:hover {
  background-color: transparent !important;
  color: black !important;
  font-weight: 600;
}
.navbar-in-active {
  font-weight: 600;
}
.background-pic {
  background-image: linear-gradient(to bottom, rgba(255,255,255,0) 10%,
          rgba(0,0,0,1)), url(bg.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right top;
  background-size: cover;
  width: 100vw;
  height: 100%;
}
.header-ania {
  text-transform: uppercase;
}
.divider {
  background-color: black;
  height: 1px;
  width: 100%;
}
.text-black {
  color: black !important;
}
.text-toksyk {
  color: rgb(230,190,232)
}
.text-toksyk-dark {
  color: rgb(191,121,160)
}
.footer-elem {
  text-align: center;
}
.footer-text {
  margin-left: 15px;
  display: inline;
  font-weight: bold;
  color: aliceblue;
}
.footer-text > a {
  color: aliceblue;

}
.footer {
  float: bottom;
  background: transparent !important;
}
.social-link {
    padding: 5px;
}
.text-align-center {
    text-align: center;
}
@media screen and (min-width: 1023px) {
  .navbar-ania {
    padding: 0 64px;
  }
}
@media screen and (max-width: 1023px) {
  .navbar-menu {
    background-color: transparent;
    padding: 0.5rem 0;
    box-shadow: none;
  }
  .navbar-ania {
    padding: 0;
  }
  .navbar {
    min-height: 0;
  }
}


#shadowBox {
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.2);
    /* Black w/opacity/see-through */
    border: 3px solid;
}

.rainbow {
    text-align: center;
    text-decoration: underline;
    font-size: 100px;
    font-family: monospace;
    letter-spacing: 5px;
}
.rainbow_text_animated {
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 3s ease-in-out infinite;
    background-size: 400% 100%;
}

@keyframes rainbow_animation {
    0%,100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}
.mic {
    display:block;
    margin:auto;
}